import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { cctc_check_for_role } from './roles.cctc.utils';
import { MCODES, menu_items_check_for_role } from './roles.menu.utils';
import { staff_check_for_role } from './roles.staff.utils';

@Injectable({ providedIn: 'root' })
export class RolesPermissionService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    get data() {
        return this._data;
    }
    get dataSub() {
        return this._dataSub;
    }

    constructor(
        private accountService: AccountService,
        private hostnameService: HostnameService,
        protected readonly keyCloakService: KeycloakService
    ) {
        this._data.clear();
        this._data.set('roles', []);
        this._data.set('menu_items', [MCODES.dashboard, MCODES.taikhoan]);
        this._data.set(MCODES.staff, {});
        this._data.set(MCODES.cctc, {});
    }

    changeForRole(newRoles: string[]) {
        let currentValues: any = this._data.get('roles');
        if (currentValues.length == newRoles.length && newRoles.every((role: string) => currentValues.includes(role)))
            return;

        this._data.set('roles', newRoles);
        this._data.set('menu_items', menu_items_check_for_role(newRoles));
        this._data.set(MCODES.staff, staff_check_for_role(newRoles));
        this._data.set(MCODES.cctc, cctc_check_for_role(newRoles));
        this._dataSub.next(this._data);
    }
}
