import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CctcService } from './cctc.service';

@Injectable({ providedIn: 'root' })
export class CctcDataService {
    private _data: any[] = [];
    private _dataSub: Subject<any[]> = new Subject();

    public get data(): any[] {
        return this._data;
    }
    public set data(value: any[]) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any[]> {
        return this._dataSub;
    }

    constructor(private cctcService: CctcService) {}

    trigger() {
        this._data = Array.from(this._data);
        this._dataSub.next(this._data);
    }

    async fetch() {
        let result: any = await this.cctcService.getAll();
        if (result.status == 'error') this.data = [];
        this.data = result.cctcs.sort((a: any, b: any) => {
            if (a.org_code > b.org_code) return 1;
            if (a.org_code < b.org_code) return -1;
            return 0;
        });
    }

    async fetchIfNotExists() {
        if (Array.isArray(this._data) && this._data.length > 0) return;
        await this.fetch();
    }

    getOrgByMacb(ma_cb: string) {
        let results: any[] = [];

        this._data.forEach((cctc: any) => {
            let thanh_vien: any = cctc.thanh_vien;
            if (!Array.isArray(thanh_vien)) return;
            for (let tv of thanh_vien) if (tv.ma_cb == ma_cb) return results.push(cctc);
        });

        return results;
    }
}
