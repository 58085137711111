// CONSTABCE
export const STAFF_CODES = {
    actions: {
        checked: 'checked', // Xem
        xem: 'xem', // Xem
        them: 'them', // Thêm
        tao_moi: 'tao_moi', // Tạo mới
        xoa: 'xoa', // Xóa
        sua: 'sua', // Sửa
        xuat: 'xuat', // Xuất
    },
};

// ROLES
export const STAFF_ROLES: Map<string, any> = new Map([
    [
        'hids.canbo.chuyenvien',
        {
            global_actions: [STAFF_CODES.actions.them],
            // prettier-ignore
            actions: [
                STAFF_CODES.actions.checked, 
                STAFF_CODES.actions.xem, STAFF_CODES.actions.them, STAFF_CODES.actions.tao_moi, STAFF_CODES.actions.xoa, STAFF_CODES.actions.sua, STAFF_CODES.actions.xuat,
            ],
        },
    ],
    [
        'hids.canbo.lanhdao',
        {
            global_actions: [STAFF_CODES.actions.them],
            // prettier-ignore
            actions: [
                STAFF_CODES.actions.checked, 
                STAFF_CODES.actions.xem, STAFF_CODES.actions.them, STAFF_CODES.actions.tao_moi, STAFF_CODES.actions.xoa, STAFF_CODES.actions.sua, STAFF_CODES.actions.xuat,
            ],
        },
    ],
]);

// FUNCTIONS
export function staff_check_for_role(roles: string[]) {
    let actions = new Set();
    let global_actions: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = STAFF_ROLES.get(role);
        if (!role_info) return;
        role_info.actions.forEach((item: string) => actions.add(item));
        role_info.global_actions.forEach((item: string) => global_actions.add(item));
    });

    return {
        actions: [...actions],
        global_actions: [...global_actions],
    };
}
