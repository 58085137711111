import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'menuitems.tai_khoan',
    type: 'sub',
    icon: 'manage_account',
    children: [{ state: 'account-info', name: 'menuitems.account_info', type: 'link' }],
};

export const MENU_ITEMS_LUUTRU = {
    state: 'storage',
    name: 'menuitems.luu_tru',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'files-downloaded', name: 'menuitems.file_downloaded', type: 'link' }],
};

export const MENU_ITEMS_TIENICH = {
    state: 'utilities',
    name: 'menuitems.tien_ich',
    type: 'sub',
    icon: 'extension',
    children: [
        { state: ['standard-address-and-general', 'address'], name: 'menuitems.standard_address', type: 'link' },
        { state: ['standard-address-and-general', 'general'], name: 'menuitems.standard_general_data', type: 'link' },
        { state: 'standard-basic-data', name: 'menuitems.standard_staff', type: 'link' },
    ],
};

export const MENU_ITEMS_STAFF = {
    state: 'staff',
    name: 'menuitems.staff',
    type: 'sub',
    icon: 'work',
    children: [
        { state: 'list', name: 'menuitems.staff_list', type: 'link' },
        { state: 'history', name: 'menuitems.staff_history', type: 'link' },
    ],
};

export const MENU_ITEMS_STAFF_ACCOUNT = {
    state: 'staff-account',
    name: 'menuitems.staff_account',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'manage-staff-account', name: 'menuitems.manage_staff_account', type: 'link' }],
};

export const MENU_ITEMS_CCTC = {
    state: 'cctc',
    name: 'menuitems.cctc_title',
    type: 'sub',
    icon: 'subdirectory_arrow_right',
    children: [{ state: 'list', name: 'menuitems.cctc_list', type: 'link' }],
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.cctc, MENU_ITEMS_CCTC],
    [MCODES.staff, MENU_ITEMS_STAFF],
    [MCODES.staff_account, MENU_ITEMS_STAFF_ACCOUNT],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.cctc,
    MCODES.staff,
    MCODES.staff_account,
    MCODES.luutru,
    MCODES.tienich,
    MCODES.taikhoan,
];
