import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class CctcService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async add(data: any) {
        return this.httpClient
            .post<any>(
                this.hostnameService.HidsAPI_Producerv1 + '/cctc/add',
                {
                    data: data,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async edit(cctc_id: string, data: any, metadata: any) {
        return this.httpClient
            .put<any>(
                this.hostnameService.HidsAPI_Producerv1 + '/cctc/edit?id=' + cctc_id,
                {
                    data: data,
                    metadata: metadata,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async delete(cctc_id: any) {
        return this.httpClient
            .delete<any>(this.hostnameService.HidsAPI_Producerv1 + '/cctc/delete?id=' + cctc_id, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async syncBpct() {
        return this.httpClient
            .get<any>(this.hostnameService.HidsAPI_Producerv1 + '/cctc/sync-bpct', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async addMultipleByFile(file: File, input_type: string) {
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('input_type', String(input_type));

        return this.httpClient
            .post<any>(this.hostnameService.HidsAPI_Producerv1 + '/cctc/add-multiple-by-file', formData, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getAll() {
        return this.httpClient
            .get<any>(this.hostnameService.HidsAPI_Readv1 + '/cctc/get-all', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getOrgCodeByMaCb(ma_cb: string) {
        return this.httpClient
            .get<any>(this.hostnameService.HidsAPI_Readv1 + '/cctc/get-org-code-by-ma-cb?ma-cb=' + ma_cb, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async downloadTemplate() {
        return this.httpClient
            .get<any>(this.hostnameService.HidsAPI_Readv1 + '/cctc/download-template', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }
}
